<template>
  <div>
    <h1>Liens utiles</h1>
    <ul>
      <li>Générateur de QR Code gratuit : <a href="https://fr.qr-code-generator.com/">https://fr.qr-code-generator.com/</a></li>
    </ul>
    <ul>
      <li>Configuration des scripts vue-cli pour lancement (debug, prod) de l'appli : <a href="https://cli.vuejs.org/guide/cli-service.html#using-the-binary">https://cli.vuejs.org/guide/cli-service.html#using-the-binary</a></li>
    </ul>
    <ul>
      <li>Documentation <b>i18n</b> : <a href="https://kazupon.github.io/vue-i18n/introduction.html">https://kazupon.github.io/vue-i18n/introduction.html</a></li>
    </ul>
  </div>
</template>

<script>

export default{
  mounted(){
    this.$hideLoader();
  }
}
</script>

